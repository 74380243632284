
import ButtonComponent from '@/components/ButtonComponent.vue';
import SnackbarMixin from '@/mixins/snackbarMixin';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { Log } from '@/models/Entities/Log';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import LegacyTableComponent from '@/models/Table/Legacy/LegacyTableComponent.vue';
import { convertToKeyValueObj } from '@/util/object/convertToKeyValueObj';
import Vue from 'vue';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import ProfileDetailsBaseComponent from './ProfileDetailsBaseComponent.vue';

class TableViewModel {
  entryDate: Date;
  comment: string;
  topic: string | null;
  createdBy: string | null;

  constructor(log: Log) {
    this.entryDate = log.createDateTime;
    this.comment = log.text;
    this.topic = log.actionType;
    this.createdBy = log.logCreatorDisplayName;
  }
}

const sections = convertToKeyValueObj({
  NOTES: 'Notes',
  IMMEDIATE_ACTION: 'Immediate Action'
});

class Model extends BaseCandidate {
  constructor(partial?: Partial<Model>) {
    super(partial);
  }
}

@Component<NotesAndOrdersPage>({
  components: {
    LegacyTableComponent,
    ProfileDetailsBaseComponent,
    ButtonComponent
  }
})
export default class NotesAndOrdersPage extends Mixins(Vue, SnackbarMixin) {
  @Prop({ required: true })
  applicant!: Applicant;

  model = new Model();

  defaultSortColumn: keyof TableViewModel = 'entryDate';

  sections = sections;

  @Watch('applicant')
  updateModel(): void {
    this.model = new Model(this.applicant);
  }

  @Watch('$route.query', { immediate: true })
  watchRouterToKeepSections(): void {
    this.model = new Model(this.applicant);
  }

  openNotes(immediate_Action: boolean): void {
    // Query params are strings
    const id = String(this.model.id);
    const immediateAction = String(immediate_Action);
    const path = `/applicant/${id}/notes-and-orders?immediateAction=false&showNotes=true`;
    // handle duplication navigation error
    if (this.$route.fullPath !== path) {
      this.$router.push({
        params: {
          id
        },
        query: {
          immediateAction,
          showNotes: 'true'
        }
      });
    }
  }

  get immediateActionData(): TableViewModel[] {
    return this.model.logs
      .filter((log: Log) => log.actionRequired)
      .map((log: Log) => {
        return new TableViewModel(log);
      });
  }

  get notesData(): TableViewModel[] {
    return this.model.logs
      .filter((log: Log) => !log.actionRequired)
      .map((log: Log) => {
        return new TableViewModel(log);
      });
  }
}
