var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("profile-details-base-component", [
    _c(
      "div",
      { staticClass: "mb-10 h-1/2" },
      [
        _c(
          "div",
          { staticClass: "flex items-center" },
          [
            _c(
              "h3",
              {
                staticClass: "section-header",
                attrs: { id: _vm.sections.IMMEDIATE_ACTION.key },
              },
              [_vm._v(" " + _vm._s(_vm.sections.IMMEDIATE_ACTION.label) + " ")]
            ),
            _c(
              "button-component",
              {
                staticClass: "ml-auto",
                attrs: {
                  textOrBorderColor: "white",
                  bg: "val-button-blue",
                  "data-cy": "newImmediateAction",
                },
                on: {
                  click: function ($event) {
                    return _vm.openNotes(true)
                  },
                },
              },
              [_vm._v(" Add Immediate Action ")]
            ),
          ],
          1
        ),
        _c("legacy-table-component", {
          attrs: {
            data: _vm.immediateActionData,
            isSortable: true,
            defaultSortColumn: _vm.defaultSortColumn,
            columnDataTextWrapping: true,
            isFilterable: true,
            staticPaginatedRows: 5,
            emptyTableMessage: "0 Immediate Actions",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mb-10 h-1/2" },
      [
        _c(
          "div",
          { staticClass: "flex items-center" },
          [
            _c(
              "h3",
              {
                staticClass: "section-header",
                attrs: { id: _vm.sections.NOTES.key },
              },
              [_vm._v(" " + _vm._s(_vm.sections.NOTES.label) + " ")]
            ),
            _c(
              "button-component",
              {
                staticClass: "ml-auto",
                attrs: {
                  "data-cy": "newNote",
                  textOrBorderColor: "white",
                  bg: "val-button-blue",
                },
                on: {
                  click: function ($event) {
                    return _vm.openNotes(false)
                  },
                },
              },
              [_vm._v(" Add Note ")]
            ),
          ],
          1
        ),
        _c("legacy-table-component", {
          attrs: {
            data: _vm.notesData,
            isSortable: true,
            defaultSortColumn: _vm.defaultSortColumn,
            columnDataTextWrapping: true,
            isFilterable: true,
            staticPaginatedRows: 5,
            emptyTableMessage: "0 Notes",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }